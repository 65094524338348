.menuRow strong {

  color: white;

}

.eventIcon {

	z-index: 10 !important;

  > div {

    text-align: center;
    overflow: visible;
  
    display: inline-block;
  
    color: rgba(0, 0, 0, 0.6);
  
    background: rgb(255, 255, 255);
    padding: 2px 5px;
    border-radius: 5px;
  
    border: 1px solid;
  
    line-height: 8px;
    font-size: 10px;
  
  }

}

.validPositionFalse {

  opacity: 0.4;

  &:hover {

    opacity: 1;

  }
  
}

.fade {
  animation: highlight 1s;
}

.cursor-pointer {

  cursor: pointer;
  
}

.modal-dialog {
  width: 100%;
  height: 90%;
  padding: 0;
  margin: auto;

  max-width: 90%;
  margin-top: 2%;
}

.modal-content {
  max-height: 90%;
  min-height: 100%;
  height: auto;
  border-radius: 0;

  overflow: scroll;
}

.timestamp {

  padding-left: 7px;
  padding-right: 7px;
  
  min-width: 75px;
  margin-right: 7px;
  
  border-right: 2px solid #999;

  > span {

    display: inline-block;
    border-radius: 5px;
    
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2px 5px;

    margin: 5px 0;

    font-size: 12px;

  }

}
@keyframes highlight {
  from {
    opacity: 0;
  }
  to {
   opacity: 1;
  }
}

.beacon {

  display: inline-block;

  min-width: 180px;

  border: 1px solid black;
  padding: 0px 5px;

  border-radius: 5px;
  
  margin-right: 5px;
  margin-bottom: 3px;

  font-size: 12px;

  background-color: white;

  font-family: monospace;

  > strong {

    display: inline-block;
    min-width: 80px;


  }

  &.beacon-valid {

    background-color: #378589;
    color: white;

  }

  &.beacon-invalid {

    opacity: 0.5;

  }

  &.beacon-found.beacon-invalid {

    opacity: 1;

    background-color: #f4c040;
    color: white;

    &::after {

      content: "Calculated beacon RSSI not sufficient (<0)";
      
    }

  }

  &.zone-invalid {

    opacity: 1;

    background-color: #d0382a !important;
    color: white;

    &::after {

      content: "Beacon misconfigured (zone missing)" !important;

    }

  }

  &::after {

    padding: 2px;
    display: none;
    
    position: absolute;
    z-index: 1000;

    border-radius: 5px;

    background-color: #999;
    border: 1px solid black;

    margin-left: -6px;
    margin-top: -44px;

    font-weight: bolder;

  }

  &:hover::after {

    display: block;
    
  }

}