.react-select {
  &__option {
    display: block;
    font-size: 16px;
    color: rgb(36, 36, 36);
    padding: 11px 12px;
    width: 100%;
    user-select: none;
    box-sizing: border-box;
    &:hover {
      background-color: #DDD;
    }
    &_selected {
      background-color: #DDD;
      &:hover {
        background-color: #DDD;
      }
    }
  }
}